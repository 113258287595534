import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import AddtoCart from './AddtoCart';
import HeaderMenu from './HeaderMenu';

function Header() {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 50) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header-navbar ${isHeaderFixed ? 'fixed' : ''}`}>
      <Container fluid className='header-container-main'>
        <Navbar expand="lg">
          <Link to="/" className='header-logo'>
            <img src='./kabab-house-Left-Right.jpg' className='image-fluid logo-image-header-main' alt="Bkabab Logo" />
          </Link>
          <div className='ms-auto Addtocart-header1'>
            <AddtoCart />
          </div>
          <div className='Header-links-1'>
            <HeaderMenu />
          </div>
          <div className='ms-auto Addtocart-header2'>
            <AddtoCart />
          </div>
        </Navbar>
      </Container>
      <div className='Header-links-2'>
        <HeaderMenu />
      </div>
      {/* WhatsApp Logo */}
      <div className="whatsapp-logo">
        <a onClick={() => window.open('https://wa.me/+97316161000?text=Hello%20there!', '_blank')} target="_blank" rel="noopener noreferrer">
          <img className='whatsapp-image' src="./icons8-whatsapp-144.png" alt="WhatsApp" />
        </a>
      </div>
    </div>
  );
}

export default Header;
